<template>
  <div class="container">
    <el-card>
      <div>
        <section id="banner">
          <div class="inner" v-if="showSuccess">
            <i class="fas fa-check-circle action-icon-without-pointer" />
            <h1>綁定成功！</h1>
            <h3 class="pb-2"></h3>
            Go Back To
            <template v-if="lastPageIsIvyWay">
              <router-link :to="lastPageRoute">Last Page</router-link>
              Or
            </template>
            <router-link :to="{ name: 'LandingPage' }">Home</router-link>
          </div>
        </section>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import userApi from "@/apis/user";

export default {
  metaInfo() {
    return {
      title: "Link Student - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      showSuccess: false
    };
  },
  computed: {
    parentId() {
      return this.$route.query.parent;
    },
    studentId() {
      return this.$route.query.student;
    },
    token() {
      return this.$route.query.token;
    }
  },
  watch: {},

  async mounted() {
    await userApi.connectStudentAndParentWithoutLogin({ token: this.token });
    this.showSuccess = true;
  },

  methods: {}
};
</script>

<style scoped>
.container {
  padding-top: 20px;
}
#banner {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding: 2em 0 6em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 75vh;
  height: 75vh;
  position: relative;
  text-align: center;
  color: #303133;
}

.action-icon-without-pointer {
  font-size: 128px;
  margin: 12px 0px;
  animation: spin 3s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  0% {
    transform: rotate(380deg);
  }

  70% {
    transform: rotate(330deg);
  }

  100% {
    transform: rotate(740deg);
  }
}
</style>
